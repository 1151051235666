import React, { useContext } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from "react-router-dom";



import aviso from './img/aviso-covid.jpg'
import discrecional from './img/anuncio-discrecional.jpg'
import navidad2024 from './img/navidad_2024.png'
//import noticia1 from './img/noticia 1.jpg';
import sessionContext from '../../context/session/sessionContext';


const images = [
    { name: "discrecional", photo: discrecional },
    { name: "coche_nuevo_1", photo: "https://documents.enerlence.com/f/d9c8061e04d24990b7c9/?dl=1" },
    { name: "coche_nuevo_2", photo: "https://documents.enerlence.com/f/f007d58a79cc4346b0c6/?dl=1" },
    { name: "coche_nuevo_3", photo: "https://documents.enerlence.com/f/50ae8abab6a644b6bd85/?dl=1" },
    { name: "coche_nuevo_4", photo: "https://documents.enerlence.com/f/4c37e3d6fa394479b9ac/?dl=1" },
    { name: "coche_nuevo_5", photo: "https://documents.enerlence.com/f/64d819a6611c40fca8a0/?dl=1" },

   

]

const links = {
    // aviso: { ruta: "", activePage : ""} ,
    // bienvenido: { ruta: "", activePage : ""} ,
    discrecional:{ ruta: "/discrecionales", activePage : "discrecionales"} ,
//     especial_navidad:{ ruta: "", activePage : ""} ,
//     especial_navidad_2:{ ruta: "", activePage : ""} ,
 }



const PhotoCarrousel = () => {

    const _sessionContext = useContext(sessionContext)


  const { setActivePage }  = _sessionContext

    let history = useHistory();

    const linkOnClick = image_name => {

        if (links[image_name] != "") {

            setActivePage(links[image_name].activePage)
            history.push(links[image_name].ruta)

        }

    }

    return (
        <div id="photo-carousel">

            <Carousel infiniteLoop={true} autoPlay={true} interval={6000} showThumbs={false} transitionTime={600} showStatus={false}>
                {
                    images.map((image, index) => (
                        <div onClick={() => linkOnClick(image.name)} key={index}  style={ links[image?.name]?.ruta !== "" ? {cursor: "pointer"} : null}>
                            <img src={image.photo} alt={image.name} height={"900px"}/>

                        </div>
                    ))
                }


            </Carousel>


        </div>
    )
}

export default PhotoCarrousel;
